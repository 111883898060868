import React, { useState } from 'react'

import './styles/globalStyles.scss'
import Game from './index.jsx'
import HomeScreen from './home.jsx'
import Footer from '../../components/Footer/Footer.jsx'

const App = () => {
    const [gameStarted, setGameStarted] = useState(false)
    const [gameFinished, setGameFinished] = useState(false)
    const [reward, setReward] = useState('')

    return (
        <main>
            {gameStarted ? (
                <Game
                    setGameFinished={setGameFinished}
                    setReward={setReward}
                    setGameStarted={setGameStarted}
                />
            ) : (
                <HomeScreen
                    gameFinished={gameFinished}
                    reward={reward}
                    setGameStarted={setGameStarted}
                />
            )}

            <Footer />
        </main>
    )
}

export default App
