import React, { useEffect, useState } from 'react'
import { Button } from 'antd'

import './home.scss'
import handImg from './data/hand.svg'
import { useUser } from '../../services'
import { useTelegram } from '../../hooks/useTelegram'

const HomeScreen = ({ gameFinished, setGameStarted, reward }) => {
    const { useMiningBonus } = useUser()
    const miningMutate = useMiningBonus()
    const { user } = useTelegram()
    const [bonusSaved, setBonusSaved] = useState(false)

    const handleSaveBonus = () => {
        miningMutate.mutate(
            { chatId: user?.id, mine: bonusSaved ? 0 : reward },
            {
                onSuccess: (res) => {
                    setBonusSaved(true)
                },
            }
        )
    }

    useEffect(() => {
        if (gameFinished && !bonusSaved) {
            handleSaveBonus()
        }
    }, [gameFinished, bonusSaved])

    return (
        <div className="home">
            <section className="home__section">
                <img className="home__hand" src={handImg} alt="hand" />

                <div className="home__inner">
                    {gameFinished ? (
                        <>
                            <p className="home__score">Общий балл:</p>
                            <h2 className="home__title">{`${reward} заработано`}</h2>
                        </>
                    ) : (
                        <h1 className="home__title">
                            Кто хочет стать миллионером?
                        </h1>
                    )}

                    <Button onClick={() => setGameStarted(true)} block>
                        {' '}
                        {gameFinished ? 'Попробуйте еще раз' : 'Начинать'}
                    </Button>
                </div>
            </section>
        </div>
    )
}

export default HomeScreen
