import React, { useEffect, useState } from 'react'
import { Button, message } from 'antd'
import {
    InstagramOutlined,
    WhatsAppOutlined,
    CheckCircleOutlined,
    YoutubeOutlined,
} from '@ant-design/icons'

import './EarnPageStyles.scss'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import EarnModal from '../../components/EarnModal'
import TelegramIcon from '../../assets/images/telegram-logo-svgrepo-com.svg'

import { useTask } from '../../services'
import { useTelegram } from '../../hooks/useTelegram'

const iconMap = {
    InstagramIcon: InstagramOutlined,
    WhatsAppIcon: WhatsAppOutlined,
    TelegramIcon: () => (
        <img src={TelegramIcon} width={24} height={24} alt="TelegramIcon" />
    ),
    YoutubeIcon: () => YoutubeOutlined,
}

export default function EarnPage() {
    const [loadings, setLoadings] = useState([])
    const [earnModal, setEarnModal] = useState(false)
    const [selectecTask, setSelectecTask] = useState({})
    const { user } = useTelegram()
    const [messageApi, contextHolder] = message.useMessage()
    const { useGetTasks, useCompleteTask, useCheckCodeTask } = useTask()
    const [reloadHeader, setReloadHeader] = useState(false)

    const { data: dataTasks, refetch } = useGetTasks(user?.id)
    const taskMutate = useCompleteTask()
    const taskCodeMutate = useCheckCodeTask()
    const tasks = dataTasks || []

    const restrictedUserIds = [
        2006591160, 712294264, 1386444614, 927908860, 8111193785, 7236025787,
        5310523362, 5681601224, 860291767, 7564978215, 1065888139, 561970523,
        1942962074, 5223356915, 1814066941, 1098988113, 1306717665, 1717530267,
        5097750540, 1243268886, 6103579336, 7001484221, 5122906778, 6039522174,
        1225457026, 650604361, 1012256368, 6447704166, 5118005926, 5897567461,
    ]
    const restrictedTaskLink = 'https://t.me/spin2wincoin_bot'

    // Фильтрация задач
    const filteredTasks = tasks.filter((task) => {
        if (
            restrictedUserIds.includes(user?.id) &&
            task.link === restrictedTaskLink
        ) {
            return false
        }
        return true
    })

    useEffect(() => {
        setLoadings(
            filteredTasks.map((task) => ({
                id: task.id,
                loading: task.loading,
            }))
        )
    }, [filteredTasks])

    const startTask = (taskId) => {
        const task = filteredTasks.find((task) => task.id === taskId)

        setLoadings((prevLoadings) =>
            prevLoadings.map((loading) =>
                loading.id === taskId ? { ...loading, loading: true } : loading
            )
        )

        if (task) {
            window.open(task.link, '_blank')

            setTimeout(() => {
                taskMutate.mutate(
                    { chatId: user?.id, taskId },
                    {
                        onSuccess: (res) => {
                            refetch()
                            setReloadHeader(true)
                            setLoadings((prevLoadings) =>
                                prevLoadings.map((loading) =>
                                    loading.id === taskId
                                        ? { ...loading, loading: false }
                                        : loading
                                )
                            )
                        },
                    }
                )
            }, 10000)
        }
    }

    const handleCheckTaskCode = ({ code, task }) => {
        setLoadings((prevLoadings) =>
            prevLoadings.map((loading) =>
                loading.id === task.id ? { ...loading, loading: true } : loading
            )
        )

        taskCodeMutate.mutate(
            { chatId: user?.id, taskId: task.id, code },
            {
                onSuccess: (res) => {
                    if (!res) {
                        messageApi.open({
                            type: 'error',
                            content: 'Введенный вами код неверен.',
                        })

                        setLoadings((prevLoadings) =>
                            prevLoadings.map((loading) =>
                                loading.id === task.id
                                    ? { ...loading, loading: false }
                                    : loading
                            )
                        )
                    } else {
                        refetch()
                        setEarnModal(false)
                        setReloadHeader(true)
                        setSelectecTask({})
                        setLoadings((prevLoadings) =>
                            prevLoadings.map((loading) =>
                                loading.id === task.id
                                    ? { ...loading, loading: false }
                                    : loading
                            )
                        )
                    }
                },
            }
        )
    }

    const handleClickTask = (task) => {
        if (task?.withCode) {
            window.open(task.link, '_blank')
            setSelectecTask(task)
            setEarnModal(true)
        } else {
            startTask(task.id)
        }
    }

    return (
        <div className="EarnPage">
            {contextHolder}
            <Header reload={reloadHeader} />
            <EarnModal
                open={earnModal}
                setOpen={(visible) => setEarnModal(visible)}
                checkCode={(o) => handleCheckTaskCode(o)}
                task={selectecTask}
                loading={taskCodeMutate.isLoading}
            />
            <div className="container">
                <div className="EarnPage-wrapper">
                    <div className="EarnPage-wrapper-head">
                        <h1>Заработать бонус </h1>
                    </div>
                    <div className="EarnPage-tasks">
                        {filteredTasks.map((task) => {
                            return (
                                <div
                                    className="EarnPage-tasks-task"
                                    key={task.id}
                                >
                                    <div className="task-logo">
                                        {iconMap[task.icon]
                                            ? React.createElement(
                                                  iconMap[task.icon]
                                              )
                                            : null}
                                    </div>
                                    <div className="task-title">
                                        <span>{task.title}</span>
                                        <p>+{task.coin} бонус</p>
                                    </div>
                                    <div className="task-action">
                                        {task.status ? (
                                            <CheckCircleOutlined />
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    handleClickTask(task)
                                                }
                                                loading={
                                                    loadings.find(
                                                        (item) =>
                                                            item.id === task.id
                                                    )?.loading
                                                }
                                            >
                                                start
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
